import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Img from "gatsby-image";
import { getFluid } from "../../util/helper";

const fmTitle = "A választási rendszer";
const fmSlug = "valasztasi-rendszer";
const fmDate = "2021. december 9.";

export const ValasztasiRendszerAbstract = () => (
	<section>
		<Link to={`/blog/${fmSlug}`}>
			<h1 className="h1 cursor-pointer">{fmTitle}</h1>
		</Link>
		<p className="text-gray-500 mt-2">{fmDate}</p>
		<h2 className="h2 mt-4">Az országgyűlés szerepe</h2>
		<div className="mt-4">
			A magyar állam legfőbb hatalmi szervezete az Országgyűlés. A hatalmi ágak, köztük a végrehajtó hatalom, az
			igazságszolgáltatás, és törvényhozás egymáshoz való viszonyát az az Alaptörvény határozza meg.
		</div>
		<div className="mt-4">
			<span>Az Országgyűlés Magyarországon végső soron az összes hatalmi ág felé nő:</span>
			<ul className="mt-4 list-disc list-outside ml-8">
				<li>Az Országgyűlés alkotja meg a törvényeket, köztük az ország alkotmányát, az Alaptörvényt is.</li>
				<li className="mt-0">
					Az Országgyűlés jelöli ki a Kormányt, amely a végrehajtó szervezeteket irányítja.
				</li>
				<li className="mt-0">
					Az Országgyűlés választja a legfőbb ügyészt és az alkotmánybírákat, ezáltal az igazságszolgáltatásra
					is nagy hatással van.
				</li>
			</ul>
		</div>
		<div className="mt-4">
			Az Országgyűlés, az a parlament létszáma 199 fő, melyet négy évente választunk újra. A legtöbb döntéshez,
			köztük kormányalakításhoz egyszerű többség, azaz 100 szavazat kell. Alkotmánymódosításhoz, illetve a
			sarkalatos törvények módosításához ⅔-os többség, azaz 133 szavazat szükséges.
		</div>
		<div className="mt-4">
			<div className="flex justify-between">
				<div></div>
				<div>
					<Link className="link" to={`/blog/${fmSlug}`}>
						tovább ⟶
					</Link>
				</div>
			</div>
		</div>
	</section>
);

interface ArticleProps {
	fluids: { [key: string]: any };
}

const FullArticle = (props: ArticleProps) => (
	<section>
		<h1 className="h1">{fmTitle}</h1>
		<p className="text-gray-500 mt-2">{fmDate}</p>
		<h2 className="h2 mt-4">Az országgyűlés szerepe</h2>
		<p className="mt-4">
			A magyar állam legfőbb hatalmi szervezete az Országgyűlés. A hatalmi ágak, köztük a végrehajtó hatalom, az
			igazságszolgáltatás, és törvényhozás egymáshoz való viszonyát az az Alaptörvény
			<sup>
				<a className="link" href="#cite_note-1">
					[1]
				</a>
			</sup>{" "}
			határozza meg.
		</p>
		<div className="mt-4">
			<span>Az Országgyűlés Magyarországon végső soron az összes hatalmi ág felé nő:</span>
			<ul className="mt-4 ul">
				<li>Az Országgyűlés alkotja meg a törvényeket, köztük az ország alkotmányát, az Alaptörvényt is.</li>
				<li>Az Országgyűlés jelöli ki a Kormányt, amely a végrehajtó szervezeteket irányítja.</li>
				<li>
					Az Országgyűlés választja a legfőbb ügyészt és az alkotmánybírákat, ezáltal az igazságszolgáltatásra
					is nagy hatással van.
				</li>
			</ul>
		</div>
		<p className="mt-4">
			Az Országgyűlés, az a parlament létszáma 199 fő, melyet négy évente választunk újra. A legtöbb döntéshez,
			köztük kormányalakításhoz egyszerű többség, azaz 100 szavazat kell. Alkotmánymódosításhoz, illetve a
			sarkalatos törvények módosításához ⅔-os többség, azaz 133 szavazat szükséges.
		</p>
		<h2 className="h2 mt-4">Vegyes rendszer</h2>
		<p className="mt-4">
			A jelenlegi Országgyűlési választási rendszer a 2011-ben elfogadott új választási törvény elfogadása óta van
			érvényben
			<sup>
				<a className="link" href="#cite_note-2">
					[2]
				</a>
			</sup>
			. A 4 évente tartott Országgyűlési választásokon két ágon érkeznek be szavazatok, ami azt jelenti, hogy a
			legtöbb választópolgár két szavazatot adhat le: az egyiket egyéni jelöltre, a másikat pedig egy országos
			listára. Előzetesen regisztrált nemzetiségi szavazók pártlistás szavazatukat nemzetiségi önkormányzati
			szavazatra cserélhetik. Magyarországi lakcímmel nem rendelkező magyar állampolgárok (akik döntően a környező
			országokban élő határon túli magyarok) levélben szavazhatnak az országos pártlistákra. Nekik egyéni
			szavazatuk nincsen.
		</p>
		<div className="flex justify-around mt-4">
			<Img fluid={props.fluids.vegyesRendszer} alt="Vegyes rendszer" style={{ width: "1024px" }} />
		</div>
		<h2 className="h2 mt-4">Egyéni választókerületek</h2>
		<p className="mt-4">
			Az ország 106 választókerületre van osztva, ezek mindegyike egy képviselőt küld a parlamentbe. A
			választókerületek nagyjából egyenlő méretűek, és hozzávetőlegesen 75 ezer választópolgárra és 50 ezer
			tényleges szavazóra jut egy választókerület. Budapest 18 választókerületre van osztva, a legnagyobb megye
			(Pest) 12-re, míg a legkisebb (Nógrád) 2-re.
		</p>
		<p className="mt-4">
			Az egyéni választókerületekben független vagy pártok által támogatott jelöltek versengenek a kiosztott
			mandátumokért. A mandátumot az kapja, akire az adott kerületben a legtöbb szavazat érkezett. Nincsen
			eredményességi küszöb: nem kell az győztesnek 50%-ot elérnie. A régi, 2010 előtti rendszerben kétfordulós
			volt a választás, és az első fordulóban több jelölt indulhatott, de a második fordulóban már döntően csak a
			legjobb két vagy három jelölt versengett.
		</p>
		<p className="mt-4">
			A kompenzációs szavazat-számítás miatt az egyéni szavazatok a listás mandátumok kiosztását is befolyásolják.
			A kompenzációs szavazatok számítása a következő: az egyéni kerületekben a győztes jelölt pártja jutalmul
			megkapja szavazatként a saját és a második helyezett jelölt szavazatszáma közötti különbséget. A vesztes
			jelölt teljes szavazatszáma beszámít vesztes-kompenzációként az országos listás számítása. Független
			jelöltek és a parlamenti küszöböt el nem érő pártok jelöltjeinek a kompenzációs szavazat elvész.
		</p>
		<h2 className="h2 mt-4">Országos lista</h2>
		<p className="mt-4">
			Összesen 93 mandátumot osztanak szét a listás szavazatok arányai alapján. A listás helyeket főként pártok
			kapják, de 2018 óta a nemzetiségi önkormányzatok is szerezhetnek mandátumokat, ráadásul kedvezménnyel. A
			szavazók itt nem személyek, hanem listák (pártlisták vagy nemzetiségi listák) közül választhatnak. A pártok
			közül azok juthatnak be a parlamentbe, akik legalább 5%-ot megszereztek a szavazatokból.
		</p>
		<p className="mt-4">
			A 93 mandátum kiosztása a kedvezményes nemzetiségi mandátumokkal kezdődik. Nemzetiségi önkormányzatok
			nagyjából negyedannyi szavazatért juthatnak az első mandátumukhoz mint a pártok. A számolás pontos menete a
			következő: vegyük a pártlistás szavazatok számát, beleértve a kompenzációs szavazatokat is, és osszuk el
			először 93-mal illetve utána még 4-gyel. Ez a szám 2018-ban 23 831 volt. Azok a nemzetiségi listák, akik
			elérték ezt a számot, kapnak egy kedvezményes kvótát. 2018-as parlamenti választásokon vezették be egyedül a
			német nemzetiségnek sikerült kedvezményes mandátumhoz szükséges szavazatszámot elérni, 26 ezer szavazatot
			kaptak.
		</p>
		<p className="mt-4">
			A maradék országos listás mandátumot nagyjából arányosan, az úgynevezett D’Hondt
			<sup>
				<a className="link" href="#cite_note-3">
					[3]
				</a>
			</sup>{" "}
			módszer alapján osztja szét a rendszer a pártlistás szavazatok (beleértve a levélszavazatokat is) és az
			egyéni kompenzációs szavazatok között.
		</p>
		<div className="flex justify-around mt-4">
			<Img fluid={props.fluids.szavazatMandatum} alt="Szavazat-mandátum" style={{ width: "1024px" }} />
		</div>
		<p className="mt-4">
			Pártlisták számára a parlamenti küszöb 5%, ez alatt nem jár listás mandátum. Együttes indulás esetén két
			párt szövetségének a küszöb 10%, három vagy több párt esetén 15%. A parlamenti küszöböt el nem érő pártok
			listás szavazata elvész, beleértve a kompenzációs szavazatokat is. Független jelöltek után sem jár
			kompenzációs szavazat.
		</p>
		<div className="mt-4 text-xs">
			Jegyzetek:
			<ul className="mt-4 ul">
				<li id="cite_note-1">
					<a
						className="link"
						rel="noreferrer"
						target="_blank"
						href="https://net.jogtar.hu/jogszabaly?docid=a1100425.atv"
					>
						1. Alaptörvény
					</a>
				</li>
				<li id="cite_note-2">
					<a
						className="link"
						rel="noreferrer"
						target="_blank"
						href="https://net.jogtar.hu/jogszabaly?docid=a1100203.tv"
					>
						2. Választási rendszer
					</a>
				</li>
				<li id="cite_note-3">
					<a
						className="link"
						rel="noreferrer"
						target="_blank"
						href="https://hu.wikipedia.org/wiki/D%E2%80%99Hondt-m%C3%B3dszer"
					>
						3. D’Hondt-módszer
					</a>
				</li>
			</ul>
		</div>
	</section>
);

export default () => {
	const data = useStaticQuery(graphql`
		query ServicesPageQuery {
			allFile(filter: { relativePath: { in: ["vegyes-rendszer.png", "szavazat-mandatum.png"] } }) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(maxWidth: 1024) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

	const fluids = {
		vegyesRendszer: getFluid(data.allFile.edges, "vegyes-rendszer.png"),
		szavazatMandatum: getFluid(data.allFile.edges, "szavazat-mandatum.png"),
	};

	return (
		<Layout>
			<SEO title={fmTitle} />
			<div className="container px-4 py-8">
				<FullArticle fluids={fluids} />
			</div>
		</Layout>
	);
};
